var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { KeyCode } from '../../../constants/keyCode.mjs';
import { Autowired } from "../../../context/context.mjs";
import { DragAndDropService, DragSourceType } from "../../../dragAndDrop/dragAndDropService.mjs";
import { Column } from "../../../entities/column.mjs";
import { ProvidedColumnGroup } from "../../../entities/providedColumnGroup.mjs";
import { SetLeftFeature } from "../../../rendering/features/setLeftFeature.mjs";
import { removeFromArray } from "../../../utils/array.mjs";
import { ManagedFocusFeature } from "../../../widgets/managedFocusFeature.mjs";
import { TooltipFeature } from "../../../widgets/tooltipFeature.mjs";
import { AbstractHeaderCellCtrl } from "../abstractCell/abstractHeaderCellCtrl.mjs";
import { CssClassApplier } from "../cssClassApplier.mjs";
import { HoverFeature } from "../hoverFeature.mjs";
import { GroupResizeFeature } from "./groupResizeFeature.mjs";
import { GroupWidthFeature } from "./groupWidthFeature.mjs";
export class HeaderGroupCellCtrl extends AbstractHeaderCellCtrl {
  constructor(columnGroup, parentRowCtrl) {
    super(columnGroup, parentRowCtrl);
    this.columnGroup = columnGroup;
  }
  setComp(comp, eGui, eResize) {
    super.setGui(eGui);
    this.comp = comp;
    this.displayName = this.columnModel.getDisplayNameForColumnGroup(this.columnGroup, 'header');
    this.addClasses();
    this.setupMovingCss();
    this.setupExpandable();
    this.setupTooltip();
    this.setupUserComp();
    const pinned = this.getParentRowCtrl().getPinned();
    const leafCols = this.columnGroup.getProvidedColumnGroup().getLeafColumns();
    this.createManagedBean(new HoverFeature(leafCols, eGui));
    this.createManagedBean(new SetLeftFeature(this.columnGroup, eGui, this.beans));
    this.createManagedBean(new GroupWidthFeature(comp, this.columnGroup));
    this.groupResizeFeature = this.createManagedBean(new GroupResizeFeature(comp, eResize, pinned, this.columnGroup));
    this.createManagedBean(new ManagedFocusFeature(eGui, {
      shouldStopEventPropagation: this.shouldStopEventPropagation.bind(this),
      onTabKeyDown: () => undefined,
      handleKeyDown: this.handleKeyDown.bind(this),
      onFocusIn: this.onFocusIn.bind(this)
    }));
  }
  resizeLeafColumnsToFit(source) {
    // check to avoid throwing when a component has not been setup yet (React 18)
    if (!this.groupResizeFeature) {
      return;
    }
    this.groupResizeFeature.resizeLeafColumnsToFit(source);
  }
  setupUserComp() {
    let displayName = this.displayName;
    const params = {
      displayName: this.displayName,
      columnGroup: this.columnGroup,
      setExpanded: expanded => {
        this.columnModel.setColumnGroupOpened(this.columnGroup.getProvidedColumnGroup(), expanded, "gridInitializing");
      },
      api: this.gridApi,
      columnApi: this.columnApi,
      context: this.gridOptionsService.context
    };
    if (!displayName) {
      let columnGroup = this.columnGroup;
      const leafCols = columnGroup.getLeafColumns();
      // find the top most column group that represents the same columns. so if we are dragging a group, we also
      // want to visually show the parent groups dragging for the same column set. for example imaging 5 levels
      // of grouping, with each group only containing the next group, and the last group containing three columns,
      // then when you move any group (even the lowest level group) you are in-fact moving all the groups, as all
      // the groups represent the same column set.
      while (columnGroup.getParent() && columnGroup.getParent().getLeafColumns().length === leafCols.length) {
        columnGroup = columnGroup.getParent();
      }
      const colGroupDef = columnGroup.getColGroupDef();
      if (colGroupDef) {
        displayName = colGroupDef.headerName;
      }
      if (!displayName) {
        displayName = leafCols ? this.columnModel.getDisplayNameForColumn(leafCols[0], 'header', true) : '';
      }
    }
    const compDetails = this.userComponentFactory.getHeaderGroupCompDetails(params);
    this.comp.setUserCompDetails(compDetails);
  }
  setupTooltip() {
    const colGroupDef = this.columnGroup.getColGroupDef();
    const tooltipCtrl = {
      getColumn: () => this.columnGroup,
      getGui: () => this.eGui,
      getLocation: () => 'headerGroup',
      getTooltipValue: () => colGroupDef && colGroupDef.headerTooltip
    };
    if (colGroupDef) {
      tooltipCtrl.getColDef = () => colGroupDef;
    }
    const tooltipFeature = this.createManagedBean(new TooltipFeature(tooltipCtrl, this.beans));
    tooltipFeature.setComp(this.eGui);
  }
  setupExpandable() {
    const providedColGroup = this.columnGroup.getProvidedColumnGroup();
    this.refreshExpanded();
    this.addManagedListener(providedColGroup, ProvidedColumnGroup.EVENT_EXPANDABLE_CHANGED, this.refreshExpanded.bind(this));
    this.addManagedListener(providedColGroup, ProvidedColumnGroup.EVENT_EXPANDED_CHANGED, this.refreshExpanded.bind(this));
  }
  refreshExpanded() {
    const column = this.columnGroup;
    this.expandable = column.isExpandable();
    const expanded = column.isExpanded();
    if (this.expandable) {
      this.comp.setAriaExpanded(expanded ? 'true' : 'false');
    } else {
      this.comp.setAriaExpanded(undefined);
    }
  }
  getColId() {
    return this.columnGroup.getUniqueId();
  }
  addClasses() {
    const colGroupDef = this.columnGroup.getColGroupDef();
    const classes = CssClassApplier.getHeaderClassesFromColDef(colGroupDef, this.gridOptionsService, null, this.columnGroup);
    // having different classes below allows the style to not have a bottom border
    // on the group header, if no group is specified
    if (this.columnGroup.isPadding()) {
      classes.push('ag-header-group-cell-no-group');
      const leafCols = this.columnGroup.getLeafColumns();
      if (leafCols.every(col => col.isSpanHeaderHeight())) {
        classes.push('ag-header-span-height');
      }
    } else {
      classes.push('ag-header-group-cell-with-group');
    }
    classes.forEach(c => this.comp.addOrRemoveCssClass(c, true));
  }
  setupMovingCss() {
    const providedColumnGroup = this.columnGroup.getProvidedColumnGroup();
    const leafColumns = providedColumnGroup.getLeafColumns();
    // this function adds or removes the moving css, based on if the col is moving.
    // this is what makes the header go dark when it is been moved (gives impression to
    // user that the column was picked up).
    const listener = () => this.comp.addOrRemoveCssClass('ag-header-cell-moving', this.columnGroup.isMoving());
    leafColumns.forEach(col => {
      this.addManagedListener(col, Column.EVENT_MOVING_CHANGED, listener);
    });
    listener();
  }
  onFocusIn(e) {
    if (!this.eGui.contains(e.relatedTarget)) {
      const rowIndex = this.getRowIndex();
      this.beans.focusService.setFocusedHeader(rowIndex, this.columnGroup);
    }
  }
  handleKeyDown(e) {
    super.handleKeyDown(e);
    const wrapperHasFocus = this.getWrapperHasFocus();
    if (!this.expandable || !wrapperHasFocus) {
      return;
    }
    if (e.key === KeyCode.ENTER) {
      const column = this.columnGroup;
      const newExpandedValue = !column.isExpanded();
      this.columnModel.setColumnGroupOpened(column.getProvidedColumnGroup(), newExpandedValue, "uiColumnExpanded");
    }
  }
  // unlike columns, this will only get called once, as we don't react on props on column groups
  // (we will always destroy and recreate this comp if something changes)
  setDragSource(eHeaderGroup) {
    if (this.isSuppressMoving()) {
      return;
    }
    const allLeafColumns = this.columnGroup.getProvidedColumnGroup().getLeafColumns();
    let hideColumnOnExit = !this.gridOptionsService.is('suppressDragLeaveHidesColumns');
    const dragSource = {
      type: DragSourceType.HeaderCell,
      eElement: eHeaderGroup,
      getDefaultIconName: () => hideColumnOnExit ? DragAndDropService.ICON_HIDE : DragAndDropService.ICON_NOT_ALLOWED,
      dragItemName: this.displayName,
      // we add in the original group leaf columns, so we move both visible and non-visible items
      getDragItem: this.getDragItemForGroup.bind(this),
      onDragStarted: () => {
        hideColumnOnExit = !this.gridOptionsService.is('suppressDragLeaveHidesColumns');
        allLeafColumns.forEach(col => col.setMoving(true, "uiColumnDragged"));
      },
      onDragStopped: () => allLeafColumns.forEach(col => col.setMoving(false, "uiColumnDragged")),
      onGridEnter: dragItem => {
        var _a;
        if (hideColumnOnExit) {
          const unlockedColumns = ((_a = dragItem === null || dragItem === void 0 ? void 0 : dragItem.columns) === null || _a === void 0 ? void 0 : _a.filter(col => !col.getColDef().lockVisible)) || [];
          this.columnModel.setColumnsVisible(unlockedColumns, true, "uiColumnMoved");
        }
      },
      onGridExit: dragItem => {
        var _a;
        if (hideColumnOnExit) {
          const unlockedColumns = ((_a = dragItem === null || dragItem === void 0 ? void 0 : dragItem.columns) === null || _a === void 0 ? void 0 : _a.filter(col => !col.getColDef().lockVisible)) || [];
          this.columnModel.setColumnsVisible(unlockedColumns, false, "uiColumnMoved");
        }
      }
    };
    this.dragAndDropService.addDragSource(dragSource, true);
    this.addDestroyFunc(() => this.dragAndDropService.removeDragSource(dragSource));
  }
  // when moving the columns, we want to move all the columns (contained within the DragItem) in this group in one go,
  // and in the order they are currently in the screen.
  getDragItemForGroup() {
    const allColumnsOriginalOrder = this.columnGroup.getProvidedColumnGroup().getLeafColumns();
    // capture visible state, used when re-entering grid to dictate which columns should be visible
    const visibleState = {};
    allColumnsOriginalOrder.forEach(column => visibleState[column.getId()] = column.isVisible());
    const allColumnsCurrentOrder = [];
    this.columnModel.getAllDisplayedColumns().forEach(column => {
      if (allColumnsOriginalOrder.indexOf(column) >= 0) {
        allColumnsCurrentOrder.push(column);
        removeFromArray(allColumnsOriginalOrder, column);
      }
    });
    // we are left with non-visible columns, stick these in at the end
    allColumnsOriginalOrder.forEach(column => allColumnsCurrentOrder.push(column));
    // create and return dragItem
    return {
      columns: allColumnsCurrentOrder,
      visibleState: visibleState
    };
  }
  isSuppressMoving() {
    // if any child is fixed, then don't allow moving
    let childSuppressesMoving = false;
    this.columnGroup.getLeafColumns().forEach(column => {
      if (column.getColDef().suppressMovable || column.getColDef().lockPosition) {
        childSuppressesMoving = true;
      }
    });
    const result = childSuppressesMoving || this.gridOptionsService.is('suppressMovableColumns');
    return result;
  }
}
__decorate([Autowired('columnModel')], HeaderGroupCellCtrl.prototype, "columnModel", void 0);
__decorate([Autowired('dragAndDropService')], HeaderGroupCellCtrl.prototype, "dragAndDropService", void 0);
__decorate([Autowired('gridApi')], HeaderGroupCellCtrl.prototype, "gridApi", void 0);
__decorate([Autowired('columnApi')], HeaderGroupCellCtrl.prototype, "columnApi", void 0);