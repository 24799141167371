export const DEFAULT_FILTER_LOCALE_TEXT = {
  applyFilter: 'Apply',
  clearFilter: 'Clear',
  resetFilter: 'Reset',
  cancelFilter: 'Cancel',
  textFilter: 'Text Filter',
  numberFilter: 'Number Filter',
  dateFilter: 'Date Filter',
  setFilter: 'Set Filter',
  filterOoo: 'Filter...',
  empty: 'Choose One',
  equals: 'Equals',
  notEqual: 'Not equal',
  lessThan: 'Less than',
  greaterThan: 'Greater than',
  inRange: 'In range',
  inRangeStart: 'From',
  inRangeEnd: 'To',
  lessThanOrEqual: 'Less than or equals',
  greaterThanOrEqual: 'Greater than or equals',
  contains: 'Contains',
  notContains: 'Not contains',
  startsWith: 'Starts with',
  endsWith: 'Ends with',
  blank: 'Blank',
  notBlank: 'Not blank',
  andCondition: 'AND',
  orCondition: 'OR',
  dateFormatOoo: 'yyyy-mm-dd'
};