import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppState } from '../../core.store';
import { UserSettingsService } from '../user-settings.service';
import { addProfile, removeProfile, upsertProfile } from './profiles.actions';
import { Profile, ProfileFeature } from './profiles.model';
import { NewUserSettingShareType } from '@geneious/nucleus-api-client';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    private userSettingsService: UserSettingsService,
    private store: Store<AppState>,
  ) {}

  /**
   * Deletes a profile and its related data, e.g. Table Preference.
   * @param id - the ID of the profile to be deleted.
   */
  deleteProfile(id: string) {
    this.store.dispatch(removeProfile({ id }));
  }

  /**
   * Create a new profile.
   * @param name name of the profile
   * @param identifier an identifier to know where the profile can be applied. This is a generic identifier, for table
   *  preferences, it can be the name of the component where the table is located, for pipeline options it could be the
   *  name of the dialog that the profile is created for.
   * @param feature the feature that the profile was created for.
   * @param data the data of the profile.
   * @param isShared indicate if the new profile is shared among the organisation.
   */
  createProfile(
    name: string,
    identifier: string,
    feature: ProfileFeature,
    data: any,
    isShared: boolean = false,
  ): Observable<Profile> {
    const userSettingName = ProfileService.generateProfileID(name, identifier);
    const userSettingShareType = isShared
      ? NewUserSettingShareType.ReadOnly
      : NewUserSettingShareType.NotShared;
    return this.userSettingsService
      .update(feature, userSettingName, data, userSettingShareType)
      .pipe(
        map((userSetting) => ({
          id: userSetting.id,
          name,
          identifier: ProfileService.normalizeProfileIdentifier(identifier),
          feature,
          data,
          isShared,
          userID: userSetting.userID,
        })),
        tap((profile) => this.store.dispatch(addProfile({ profile }))),
      );
  }

  /**
   * Update or insert a new profile
   * @param profile the profile to upsert
   */
  upsertProfile(profile: Profile) {
    this.store.dispatch(upsertProfile({ profile }));
  }

  static normalizeProfileIdentifier(identifier: string) {
    return identifier.replace(/-/g, ' ');
  }

  /**
   * Generate the ID of the profile in store. It follows the syntax <identifier>-<name> and it replace the dashes from
   * the identifier with spaces.
   * @param name the name of the profile
   * @param identifier the identifier of the profile
   */
  static generateProfileID(name: string, identifier: string) {
    return `${ProfileService.normalizeProfileIdentifier(identifier)}-${name}`;
  }

  /**
   * Extract the identifier and name given a profile ID generated by {@link generateProfileID}
   * @param id the id generated by {@link generateProfileID}
   */
  static splitProfileUserSettingID(id: string): { identifier: string; name: string } {
    const dividerIndex = id.indexOf('-');
    return {
      identifier: id.substring(0, dividerIndex),
      name: id.substring(dividerIndex + 1, id.length),
    };
  }
}
