import { ChangeDetectionStrategy, Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from '@ag-grid-community/angular';
import { INoRowsOverlayParams } from '@ag-grid-community/core';

@Component({
  selector: 'bx-grid-no-rows-overlay',
  templateUrl: './no-rows-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class GridNoRowsOverlayComponent implements INoRowsOverlayAngularComp {
  noRowsMessage: string;
  agInit(params: INoRowsOverlayParams): void {
    this.noRowsMessage = (params as NoRowsCustomOverlayParams).noRowsMessage ?? 'No rows to show.';
  }
}

interface NoRowsCustomOverlayParams extends INoRowsOverlayParams {
  noRowsMessage?: string;
}
