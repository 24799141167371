import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { addProfile, addProfiles, removeProfile, upsertProfile } from './profiles.actions';
import { Profile } from './profiles.model';

export const adapter: EntityAdapter<Profile> = createEntityAdapter<Profile>();

export const initialState = adapter.getInitialState();

export const profilesReducer = createReducer(
  initialState,
  on(addProfiles, (state, { profiles }) => adapter.addMany(profiles, state)),
  on(removeProfile, (state, { id }) => adapter.removeOne(id, state)),
  on(addProfile, (state, { profile }) => adapter.addOne(profile, state)),
  on(upsertProfile, (state, { profile }) => adapter.upsertOne(profile, state)),
);
