@if (showTotalSelected) {
  <bx-total-selected
    [totalSelected]="noOfRowsSelected"
    [totalRows]="totalNoOfRows"
    [additionalMessage]="subTableSelectionMessage"
  ></bx-total-selected>
}
<ag-grid-angular
  class="ag-theme-balham flex-grow-1 w-100 overflow-hidden"
  [gridOptions]="gridOptions"
  [rowData]="rowData"
  [tooltipShowDelay]="500"
  (rowDataUpdated)="onRowDataChanged()"
  (gridColumnsChanged)="onGridColumnsChanged()"
  (gridReady)="onGridReady()"
  (rowDoubleClicked)="rowDoubleClicked.emit($event)"
  (rowGroupOpened)="onRowGroupOpened($event)"
  (columnPinned)="updateAndEmitGridState()"
  (columnResized)="updateAndEmitGridState()"
  (columnVisible)="updateAndEmitGridState()"
  (dragStopped)="updateAndEmitGridState()"
  (selectionChanged)="onSelectionChanged()"
></ag-grid-angular>
