import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { ILoadingOverlayAngularComp } from '@ag-grid-community/angular';
import { IAfterGuiAttachedParams, ILoadingOverlayParams } from '@ag-grid-community/core';
import { LoadingComponent } from '../../../shared/loading/loading.component';

@Component({
  selector: 'bx-grid-loading-overlay',
  templateUrl: './grid-loading-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LoadingComponent],
})
export class GridLoadingOverlayComponent implements ILoadingOverlayAngularComp {
  @HostBinding('class') readonly hostClass =
    'd-flex justify-content-center align-items-center text-muted';
  constructor() {}

  afterGuiAttached(params?: IAfterGuiAttachedParams): void {}

  agInit(params: ILoadingOverlayParams): void {}
}
