import { FormState } from '../form-state/form-state.model';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import { GridState } from '../../../features/grid/grid.interfaces';
import { EntityState } from '@ngrx/entity';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

/**
 * A profile is essentially a user setting with a special name.
 *
 * The name of that special user setting is computed as: <identifier>-<profile name>.
 * The profile identifier is different based on different type of profile, for example:
 *  - For table preferences it will be the name of the component that contains the table.
 *  - For pipeline options it will be the name of the pipeline that the options belong to.
 */
type BaseProfile = {
  id: string;
  name: string;
  identifier: PipelineFormID | string;
  isShared: boolean;
  userID: string;
  icon?: IconProp;
};

export const ProfileFeature = {
  TABLE_PREFERENCES: 'tablePreferences',
  PIPELINE_OPTIONS: 'pipelineOptions',
} as const;

export type ProfileFeature = (typeof ProfileFeature)[keyof typeof ProfileFeature];

export type TablePreferencesProfile = BaseProfile & {
  feature: typeof ProfileFeature.TABLE_PREFERENCES;
  data: GridState;
};

export type PipelineOptionsProfile = BaseProfile & {
  feature: typeof ProfileFeature.PIPELINE_OPTIONS;
  data: FormState;
};

export type ProfileTypes = {
  [ProfileFeature.TABLE_PREFERENCES]: TablePreferencesProfile;
  [ProfileFeature.PIPELINE_OPTIONS]: PipelineOptionsProfile;
};

export type Profile = ProfileTypes[ProfileFeature];

export type ProfilesState = EntityState<Profile>;
