import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { BehaviorSubject } from 'rxjs';
import { GridService } from '../grid.service';
import { getRowIdentifier } from '../../../core/ngs/getRowIdentifier';
import { ICellRendererParamsWithGridService } from '../grid.interfaces';
import { NgClass, AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-checkbox-selection-renderer',
  templateUrl: './checkbox-selection-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, AsyncPipe],
})
export class CheckboxSelectionRendererComponent implements AgRendererComponent, OnDestroy {
  checkboxChecked$ = new BehaviorSubject(false);
  // Disable checkboxes which have no id.
  hasIdentifier = false;
  private gridService: GridService;

  agInit(params: ICellRendererParamsWithGridService): void {
    this.refresh(params);
  }

  // Called by AG-GRID when refreshing the cell.
  refresh(params: ICellRendererParamsWithGridService): boolean {
    this.gridService = params.gridService;
    const selectAllState = this.gridService.selectAllState$.getValue();

    this.checkboxChecked$.next(params.node.isSelected() || selectAllState.checked);

    if (params.data && selectAllState.checked) {
      const select = this.gridService.deselectedRows.get(getRowIdentifier(params.data)) == null;
      this.checkboxChecked$.next(select);
      params.node.setSelected(select, false, 'api');
    }

    if (params.data && this.gridService.virtuallySelectedRows.get(getRowIdentifier(params.data))) {
      this.checkboxChecked$.next(true);
      params.node.setSelected(true, false, 'api');
      this.gridService.virtuallySelectedRows.delete(getRowIdentifier(params.data));
    }

    if (params.data) {
      this.hasIdentifier = typeof getRowIdentifier(params.data) !== 'undefined';
      // Tell the header renderer.
      this.gridService.gridHasRowIdentifiers = this.hasIdentifier;
    }

    return true;
  }

  checkboxClicked(event: any) {
    this.checkboxChecked$.next(event.target.checked);
    // Selection is handles by the grid in GridComponent.onRowClicked()
  }

  ngOnDestroy() {
    this.checkboxChecked$.complete();
  }
}
