import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
  TrackByFunction,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import { CleanUp } from '../cleanup';
import { AsyncPipe } from '@angular/common';

interface ErrorWithMessage {
  key: string;
  message: string;
}

/**
 * Displays error messages from custom validators of a Form Control.
 * Will not display error messages for default Angular Validators such as 'Validators.required'
 * as they do not actually contain an error message.
 *
 * Uses the Bootstrap `.invalid-feedback` class for styling and show/hide logic.
 * Please read this for details: https://getbootstrap.com/docs/5.3/forms/validation/#server-side
 * - The associated form control must be a sibling element
 * - It must have the `.is-invalid` class applied to show these form errors.
 *    You can achieve this by adding the `ngFormControlValidator` directive to
 *    the form control element.
 */
@Component({
  selector: 'bx-form-errors',
  templateUrl: './form-errors.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe],
})
export class FormErrorsComponent extends CleanUp implements OnInit {
  /** The form control to watch for errors. */
  @Input() control: AbstractControl;
  /** If true, all errors will be displayed. Otherwise, only the first error. */
  @Input() showAllErrors = true;

  @HostBinding('class') readonly hostClass = 'invalid-feedback';

  errors$: Observable<ErrorWithMessage[]>;

  readonly trackErrorByKey: TrackByFunction<ErrorWithMessage> = (_index, error) => error.key;

  ngOnInit() {
    this.errors$ = this.control.statusChanges.pipe(
      startWith(this.control.status),
      map((status) => {
        if (status === 'INVALID' && this.control.errors) {
          const errors = Object.entries({ ...this.control.errors })
            // Only include errors that actually contain an error message.
            // `Validators.required` for example does not include an error message.
            .filter(([key, message]) => key && message && typeof message === 'string')
            .map(([key, message]) => ({ key, message }));
          return this.showAllErrors || errors.length === 0 ? errors : [errors[0]];
        } else {
          return [];
        }
      }),
      shareReplay(1),
      takeUntil(this.ngUnsubscribe),
    );
  }
}
