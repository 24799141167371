import { GridOptions } from '@ag-grid-community/core';
import { GridLoadingOverlayComponent } from '../overlays/grid-loading-overlay.component';
import { getRowId } from '../../../core/ngs/getRowIdentifier';
import { GridNoFilesUploadOverlayComponent } from '../overlays/no-files-upload-overlay.component';
import { defaultValueFormatter } from '../../../core/folders/models/colDefs';

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

export const CUSTOM_OVERLAY_COMPONENTS = {
  loadingOverlay: GridLoadingOverlayComponent,
  noFilesUploadOverlay: GridNoFilesUploadOverlayComponent,
};

export const CLIENT_GRID_DEFAULTS_OPTIONS: GridOptions = {
  // we want to prefer the geneious_row_index, hence, deferring to a function which does this
  getRowId,
  // Allow dots on field names.
  suppressFieldDotNotation: true,
  suppressCopyRowsToClipboard: true,
  enableRangeSelection: true,
  // Stop columns from being deleted when they are dragged to the side of the grid.
  suppressDragLeaveHidesColumns: true,
  suppressRowClickSelection: true,
  rowHeight: 22,
  rowSelection: 'multiple',
  // Disable "no sort". At least one column must always be sorted.
  sortingOrder: ['asc', 'desc'],
  rowGroupPanelShow: 'never',
  pivotMode: false,
  defaultColDef: {
    sortable: true,
    resizable: true,
    menuTabs: ['generalMenuTab'],
    valueFormatter: defaultValueFormatter,
    comparator: (a, b) => collator.compare(a, b),
  },
  // May need to remove this if we have datepickers and so on, but for now ensures that we stop editing
  // inside the grid when the user clicks away.
  // @see https://github.com/ag-grid/ag-grid-react/issues/31
  stopEditingWhenCellsLoseFocus: true,
  enableBrowserTooltips: true,
  maintainColumnOrder: true,
};
