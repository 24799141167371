<label
  class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper mb-1"
  role="presentation"
  [ngClass]="{
    'ag-checked': checkboxChecked$ | async,
  }"
>
  <input
    #headerCheckbox
    class="ag-input-field-input ag-checkbox-input"
    type="checkbox"
    tabindex="-1"
    [disabled]="!hasIdentifier"
    (change)="checkboxClicked($event)"
  />
</label>
