import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'bx-total-selected',
  templateUrl: './total-selected.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class TotalSelectedComponent {
  @HostBinding('class') readonly hostClass = 'd-block py-2 px-3';
  @Input() totalSelected = 0;
  @Input() totalRows = 0;
  @Input() resultSetMax = 0;
  @Input() additionalMessage = '';

  get totalSelectedFormatted() {
    // TODO use FormatterService.cleanupAndShowLocalFormat(number) here?
    return this.totalSelected.toLocaleString();
  }

  get totalRowsFormatted() {
    const isNumber = this.totalRows && typeof this.totalRows === 'number';
    return isNumber ? this.totalRows.toLocaleString() : this.totalRows;
  }

  getTruncationMessage() {
    return this.resultSetMax && this.totalRows >= this.resultSetMax
      ? ` showing first ${this.resultSetMax.toLocaleString()} only.`
      : '';
  }

  getAdditionalMessage() {
    return this.additionalMessage ? ` ${this.additionalMessage}.` : '';
  }

  getPhraseEnd() {
    return this.getTruncationMessage() ? ', ' : '.';
  }
}
