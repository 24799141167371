import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Displays a spinner and "Loading...". To be used as a placeholder while content is loading.
 */
@Component({
  selector: 'bx-loading',
  standalone: true,
  templateUrl: './loading.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {}
