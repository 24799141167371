@if (validating$ | async) {
  <div class="select-overlay bg-white opacity-50">
    <bx-spinner [small]="true" class="me-1"></bx-spinner>
  </div>
}
@if (loading$ | async) {
  <div class="select-overlay">
    <bx-spinner [small]="true" class="me-1"></bx-spinner>
  </div>
}
<select
  class="form-select"
  [class.form-select-sm]="smallSize"
  [formControl]="control"
  [compareWith]="compareWith"
  ngFormControlValidator
  [enableIsValidClass]="showIsValidOutline"
  [canMarkPristineInvalid]="canMarkPristineInvalid"
>
  @for (group of selectGroupOptions; track trackBySelectOptions(i, group); let i = $index) {
    <ng-container *ngTemplateOutlet="options; context: { $implicit: group }"></ng-container>
    @if (i < selectOptions.length - 1) {
      <option disabled>--------------------------------------</option>
    }
  }

  @if (placeholder) {
    <option [ngValue]="null" disabled hidden selected>{{ placeholder }}</option>
  }
  <ng-container
    *ngTemplateOutlet="options; context: { $implicit: selectFlatOptions }"
  ></ng-container>

  @for (group of selectGroups; track trackByGroup($index, group)) {
    @if (group.label) {
      <optgroup [label]="group.label">
        <ng-container
          *ngTemplateOutlet="options; context: { $implicit: group.options }"
        ></ng-container>
      </optgroup>
    }
    @if (!group.label) {
      <ng-container
        *ngTemplateOutlet="options; context: { $implicit: group.options }"
      ></ng-container>
    }
  }

  <ng-template #options let-options>
    @if (isObjectValue) {
      @for (option of options; track trackByOption($index, option)) {
        <option [ngValue]="option.value">
          {{ option.displayName }}
        </option>
      }
    }
    @if (!isObjectValue) {
      @for (option of options; track trackByOption($index, option)) {
        <option [value]="option.value">
          {{ option.displayName }}
        </option>
      }
    }
  </ng-template>
</select>
@if (showFormErrors) {
  <bx-form-errors [control]="control" data-spec="select-errors"></bx-form-errors>
}
<ng-content select="[bxCustomFormErrors]"></ng-content>
