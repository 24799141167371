import { ChangeDetectionStrategy, Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from '@ag-grid-community/angular';
import { IAfterGuiAttachedParams, INoRowsOverlayParams } from '@ag-grid-community/core';

@Component({
  selector: 'bx-grid-no-files-upload-overlay',
  templateUrl: './no-files-upload-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class GridNoFilesUploadOverlayComponent implements INoRowsOverlayAngularComp {
  afterGuiAttached(params?: IAfterGuiAttachedParams): void {}

  agInit(params: INoRowsOverlayParams): void {}
}
