import { Injectable } from '@angular/core';
import { GridService } from '../grid.service';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { isColDef } from '../../../core/folders/models/colDefs';
import { CLIENT_CHECKBOX_COLUMN_DEF } from '../grid.constants';

@Injectable()
export class ClientGridService {
  originalColumnDefs: any[];
  originalDetailColumnDefs: any[];

  constructor() {}

  /**
   * At grid load we need to order the colDefs ("allDefs") according to any order specified by "colState".
   */
  applyColumnState(allDefs: any[], colState: any[] = []) {
    // Find any columns in "allDefs" which have matches in "colState" and order them by the order in "colState".
    const matches = colState
      // TODO Refactor to remove duplicate lookup.
      .filter((stateCol) => allDefs.find((main) => main.field === stateCol.colId))
      // Get the `columnDef` which corresponds to the matching `colState` & return it.
      .map((stateCol) => {
        const match = allDefs.find((main) => main.field === stateCol.colId);
        // Copy any relevant properties across (e.g. width).
        return Object.assign(match, stateCol);
      });
    // Get any remaining columns (e.g. in "allDefs", but not in "colState").
    const others = allDefs.filter(
      (main) => !colState.find((stateCol) => main.field === stateCol.colId),
    );
    // Note that new columns are just tacked on the end; all in "colState" have priority.
    return [...matches, ...others];
  }

  constructColumnDefs(columnDefs: any[]): any[] {
    const formattedColDefs = GridService.addDefaultValueFormatter(columnDefs).map(
      this.assignAsGeneralColumn,
    );
    return [CLIENT_CHECKBOX_COLUMN_DEF, ...formattedColDefs];
  }

  updateOriginalColumnDefs(columns: any[]) {
    this.originalColumnDefs = this.constructColumnDefs(columns);
  }

  updateOriginalDetailColumnDefs(columns: any[]) {
    this.originalDetailColumnDefs = this.constructColumnDefs(columns);
  }

  /** Private API **/

  private assignAsGeneralColumn(column: ColDef | ColGroupDef): ColDef | ColGroupDef {
    if (isColDef(column)) {
      return { ...column, enableCellChangeFlash: true, tooltipField: column.field };
    }

    return column;
  }
}
