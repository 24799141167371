@if (
  {
    checked: checkboxChecked$ | async,
    indeterminate: indeterminate$ | async,
    disable: (rowsHaveIdentifiers$ | async) !== true,
  };
  as vm
) {
  <label
    class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper mb-0"
    role="presentation"
    [ngClass]="{
      'ag-checked': vm.checked && !vm.indeterminate,
      'ag-indeterminate': vm.indeterminate,
    }"
  >
    <input
      #headerCheckbox
      class="ag-input-field-input ag-checkbox-input"
      type="checkbox"
      tabindex="-1"
      [disabled]="vm.disable"
      [checked]="vm.checked"
      [indeterminate]="vm.indeterminate"
      (change)="checkboxClicked($event)"
      data-testid="grid-select-all"
    />
  </label>
}
