import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { RowWithID, getRowIdentifier } from '../../core/ngs/getRowIdentifier';
import { isColGroupDef } from '../../core/folders/models/colDefs';

@Injectable()
export class GridService implements OnDestroy {
  // Important to set true as that's the default.
  gridHasRowIdentifiers$: Observable<boolean>;

  readonly selectAllState$: BehaviorSubject<SelectAllState>;

  readonly deselectedRows = new Map<string, RowWithID>();
  readonly virtuallySelectedRows = new Map<string, RowWithID>();
  private _gridHasRowIdentifiers$: BehaviorSubject<boolean>;

  constructor() {
    this._gridHasRowIdentifiers$ = new BehaviorSubject<boolean>(true);

    this.gridHasRowIdentifiers$ = this._gridHasRowIdentifiers$.asObservable();
    this.selectAllState$ = new BehaviorSubject<{ checked: boolean; indeterminate: boolean }>({
      checked: false,
      indeterminate: false,
    });
  }

  ngOnDestroy(): void {
    this._gridHasRowIdentifiers$.complete();
    this.selectAllState$.complete();
  }

  set gridHasRowIdentifiers(value: boolean) {
    this._gridHasRowIdentifiers$.next(value);
  }

  /** Public API **/

  clearAllSelection() {
    this.deselectedRows.clear();
  }

  // TODO Make a new function "select" which we call rather than calling gridOptions.api.select...
  // It should wrap the grid select function and then call updateSelected along with anything else.
  updateDeselected(data: RowWithID, newState: boolean) {
    if (this.selectAllState$.getValue().checked) {
      if (newState) {
        this.deselectedRows.delete(getRowIdentifier(data));
      } else {
        this.deselectedRows.set(getRowIdentifier(data), data);
      }
    }
  }

  static buildTooltip(hasIdentifier: boolean) {
    return hasIdentifier
      ? ''
      : "This is an older document that doesn't support selection. Run the comparison again to generate a new document which does.";
  }

  /** Static Functions **/

  public static addDefaultValueFormatter(columnDefinitions: ColDef[]): ColDef[] {
    return columnDefinitions
      ? columnDefinitions.map((colDef) => {
          const newColDef = Object.assign({}, colDef);
          if (!newColDef.valueFormatter) {
            newColDef.valueFormatter = (params) => GridService.dashIfMissing(params.value);
          }
          return newColDef;
        })
      : undefined;
  }

  public static addHeaderTooltip(
    columnDefinitions: (ColDef | ColGroupDef)[],
  ): (ColDef | ColGroupDef)[] {
    return columnDefinitions.map((colDef) =>
      isColGroupDef(colDef)
        ? this.addTooltipToColGroupDef(colDef)
        : this.addTooltipToColDef(colDef),
    );
  }

  public static customizeColumnDefinitions(columnDefinitions: ColDef[]): ColDef[] {
    let newDefinitions = this.addDefaultValueFormatter(columnDefinitions);
    newDefinitions = this.addHeaderTooltip(newDefinitions);
    return newDefinitions;
  }

  private static addTooltipToColDef(colDef: ColDef): ColDef {
    if (!colDef.headerTooltip) {
      return {
        ...colDef,
        headerTooltip: colDef.headerName,
      };
    }
    return colDef;
  }

  private static addTooltipToColGroupDef(colDef: ColGroupDef): ColGroupDef {
    if (!colDef.headerTooltip) {
      return {
        ...colDef,
        headerTooltip: colDef.headerName,
        children: this.addHeaderTooltip(colDef.children),
      };
    }
    return colDef;
  }

  private static dashIfMissing(value: any) {
    if (
      typeof value === 'undefined' ||
      value === null ||
      (typeof value === 'number' && isNaN(value)) ||
      value === ''
    ) {
      return '-';
    } else {
      return value;
    }
  }
}

export interface SelectAllState {
  checked: boolean;
  indeterminate: boolean;
}
