import { AgGridModule } from '@ag-grid-community/angular';
import { NgModule } from '@angular/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';

@NgModule({
  imports: [AgGridModule],
  exports: [AgGridModule],
})
export class GridModule {
  constructor() {
    LicenseManager.setLicenseKey(
      'Using_this_{AG_Grid}_Enterprise_key_{AG-054788}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{BIOMATTERS_LIMITED}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GENEIOUS}_only_for_{4}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{GENEIOUS}_need_to_be_licensed___{GENEIOUS}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{8_April_2025}____[v3]_[01]_MTc0NDA2NjgwMDAwMA==4f499affeb62fdc108dbcc27d821c546',
    );

    ModuleRegistry.registerModules([
      ClientSideRowModelModule,
      InfiniteRowModelModule,
      CsvExportModule,
      MenuModule,
      ClipboardModule,
      MasterDetailModule,
      SetFilterModule,
      RangeSelectionModule,
      RichSelectModule,
    ]);
  }
}
