import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { GridService } from '../grid.service';
import { CleanUp } from '../../../shared/cleanup';
import { IHeaderParamsWithGridService } from '../grid.interfaces';
import { NgClass, AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-header-select-all-renderer',
  templateUrl: './header-select-all-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, AsyncPipe],
})
export class HeaderSelectAllRendererComponent
  extends CleanUp
  implements IHeaderAngularComp, OnDestroy
{
  checkboxChecked$ = new BehaviorSubject(false);
  indeterminate$: Observable<boolean>;
  rowsHaveIdentifiers$: Observable<boolean>;
  @ViewChild('headerCheckbox', { static: true }) checkbox: any;

  private gridService: GridService;

  agInit(params: IHeaderParamsWithGridService): void {
    this.gridService = params.gridService;
    this.indeterminate$ = this.gridService.selectAllState$.pipe(
      map((state) => state.indeterminate),
      distinctUntilChanged(),
      takeUntil(this.ngUnsubscribe),
    );
    this.rowsHaveIdentifiers$ = this.gridService.gridHasRowIdentifiers$.pipe(
      takeUntil(this.ngUnsubscribe),
    );

    // Subscribe to selectAll from gridComponent. Will get the last emitted value of SelectAllState
    this.gridService.selectAllState$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((selectAllState) => this.checkboxChecked$.next(selectAllState.checked));
  }

  checkboxClicked(event: any) {
    const checked = event.target['checked'];
    this.checkboxChecked$.next(checked);
    this.gridService.selectAllState$.next({ checked, indeterminate: false });
  }

  refresh(): boolean {
    return false;
  }
}
