import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SelectGroup, SelectOption } from '../../core/models/ui/select-option.model';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  NG_VALUE_ACCESSOR,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { CleanUp } from '../cleanup';
import { NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { SpinnerComponent } from '../spinner/spinner.component';
import { NgFormControlValidatorDirective } from '../form-helpers/ng-form-control-validator.directive';
import { FormErrorsComponent } from '../form-errors/form-errors.component';

/**
 * This component wraps native `select` and dynamically generates the `option` elements based on
 * the `selectOptions` input. Because it is a wrapper component, using `ngFormControlValidator` and
 * `bx-form-errors` will not work, as Bootstrap won't display form feedback unless it is a sibling
 * of the `select` element. However, the component will handle this for you if you set
 * `showFormErrors` to true.
 *
 * NOTE: That due to the way this implements a ControlValueAccessor, it must be nested inside a
 * form tag.
 */
@Component({
  selector: 'bx-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    SpinnerComponent,
    FormsModule,
    NgFormControlValidatorDirective,
    ReactiveFormsModule,
    NgTemplateOutlet,
    FormErrorsComponent,
    AsyncPipe,
  ],
})
export class SelectComponent extends CleanUp implements OnChanges, OnInit, ControlValueAccessor {
  // Get Form Directive from the native select element. This form directive contains the
  // SelectControlValueAccessor which does a lot of the work for us (don't have to implement custom handling
  // for the ControlValueAccessor handlers).
  @ViewChild(FormControlDirective, { static: true }) formDirective: FormControlDirective;
  @Input() formControl: FormControl;
  @Input() formControlName: string;
  @Input() selectOptions: SelectOption<any>[] | SelectOption<any>[][] | SelectGroup<any>[];
  @Input() placeholder?: string;
  @Input() smallSize = false;
  /** Set to true to display Bootstrap `.invalid-feedback` messages for the form control's errors. */
  @Input() showFormErrors = false;
  /** Set to true to display Bootstrap's  success outline when the form control is valid. */
  @Input() showIsValidOutline = false;
  /** Note this defaults to false for bx-multi-select elements */
  @Input() canMarkPristineInvalid = true;

  /**
   * Provides a custom compare function that returns true when comparing two objects that should be
   * considered the same. This is useful when we want to set the value of the select based on a
   * stored preference, as any object stored previously will have a different reference to anything
   * in the selectOptions.
   *
   * @see SelectControlValueAccessor.compareWith
   * @param a
   * @param b
   */
  @Input() compareWith: (a: any, b: any) => boolean = (a, b) => a == b;

  selectFlatOptions: SelectOption[] = [];
  selectGroupOptions: SelectOption[][] = [];
  selectGroups: SelectGroup[] = [];
  validating$: Observable<boolean>;
  isObjectValue: boolean;
  loading$ = this.completeOnDestroy(new BehaviorSubject(true));

  protected selectOptions$ = this.completeOnDestroy(
    new ReplaySubject<SelectOption[] | SelectOption[][]>(1),
  );

  constructor(@Optional() private controlContainer: ControlContainer) {
    super();
  }

  get control(): FormControl {
    return (
      this.formControl || (this.controlContainer.control.get(this.formControlName) as FormControl)
    );
  }

  ngOnChanges({ selectOptions }: SimpleChanges) {
    this.loading$.next(selectOptions?.firstChange && selectOptions.currentValue == null);
    // This component allows 3 different types of select options to be passed in...
    if (selectOptions && Array.isArray(selectOptions.currentValue)) {
      if (selectOptions.currentValue.length > 0 && Array.isArray(selectOptions.currentValue[0])) {
        this.setValueForNestedOptions(selectOptions);
      } else if (selectOptions.currentValue[0] instanceof SelectGroup) {
        this.setValueForGroupedOptions(selectOptions);
      } else {
        this.setValueForFlatOptions(selectOptions);
      }
      this.selectOptions$.next(selectOptions.currentValue);
    } else {
      // New select options aren't an array, so clear everything.
      this.selectFlatOptions = [];
      this.selectGroupOptions = [];
      this.selectGroups = [];
      this.selectOptions$.next([]);
    }
  }

  private setValueForFlatOptions(selectOptions: SimpleChange) {
    this.selectFlatOptions = selectOptions.currentValue;
    this.selectGroupOptions = [];
    this.selectGroups = [];
    this.isObjectValue = this.selectFlatOptions.some((option) => this.isOptionValueObject(option));
    if (!this.control.value && this.selectFlatOptions.length > 0) {
      this.control.setValue(this.selectFlatOptions[0].value);
    }
  }

  private setValueForGroupedOptions(selectOptions: SimpleChange) {
    this.selectGroups = selectOptions.currentValue;
    this.selectFlatOptions = [];
    this.selectGroupOptions = [];
    this.isObjectValue = this.selectGroups.some((group) =>
      group.options.some((option) => this.isOptionValueObject(option)),
    );
    if (
      !this.control.value &&
      this.selectOptions.length > 0 &&
      this.selectGroups[0].options.length > 0
    ) {
      this.control.setValue(this.selectGroups[0].options[0].value);
    }
  }

  private setValueForNestedOptions(selectOptions: SimpleChange) {
    this.selectFlatOptions = [];
    this.selectGroupOptions = selectOptions.currentValue;
    this.selectGroups = [];
    this.isObjectValue = this.selectGroupOptions.some((options) =>
      options.some((option) => this.isOptionValueObject(option)),
    );
    if (
      !this.control.value &&
      this.selectGroupOptions.length > 0 &&
      this.selectGroupOptions[0].length > 0
    ) {
      this.control.setValue(this.selectGroupOptions[0][0].value);
    }
  }

  ngOnInit() {
    this.validating$ = this.control.statusChanges.pipe(
      startWith(this.control.status),
      map((status) => status === 'PENDING'),
      takeUntil(this.ngUnsubscribe),
    );
  }

  writeValue(value: any | any[]): void {
    this.formDirective.valueAccessor.writeValue(value);
  }

  registerOnChange(fn: () => any): void {
    this.formDirective.valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: () => any): void {
    this.formDirective.valueAccessor.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean) {
    this.formDirective.valueAccessor.setDisabledState(isDisabled);
  }

  trackByGroup(index: number, group: SelectGroup): string {
    return group.label;
  }

  trackBySelectOptions(index: number, selectOptions: SelectOption[]): string {
    return selectOptions.map((option) => option.value).join('-');
  }

  trackByOption(index: number, selectOption: SelectOption): string {
    return selectOption.value;
  }

  private isOptionValueObject(option: SelectOption) {
    return typeof option.value === 'object';
  }
}
