import { createAction, props } from '@ngrx/store';
import { Profile } from './profiles.model';

export const addProfiles = createAction('[Profiles] Add Many', props<{ profiles: Profile[] }>());

export const removeProfile = createAction('[Profiles] Remove', props<{ id: string }>());

export const upsertProfile = createAction('[Profiles] Upsert', props<{ profile: Profile }>());

export const addProfile = createAction('[Profiles] Add One', props<{ profile: Profile }>());
