import { ColDef, ColumnState, GridOptions } from '@ag-grid-community/core';
import { getRowId } from '../../core/ngs/getRowIdentifier';
import { HeaderSelectAllRendererComponent } from './header-select-all-renderer/header-select-all-renderer.component';
import { CheckboxSelectionRendererComponent } from './checkbox-selection-renderer/checkbox-selection-renderer.component';

/**
 * Default Grid Options for GridComponent
 * @type {GridOptions}
 */
export const DEFAULT_GRID_OPTIONS: GridOptions = {
  getRowId,
  // Allow dots on field names.
  suppressFieldDotNotation: true,
  suppressMultiSort: false,
  enableRangeSelection: true,
  // Stop columns from being deleted when they are dragged to the side of the grid.
  suppressDragLeaveHidesColumns: true,
  suppressCopyRowsToClipboard: true,
  rowHeight: 22,
  suppressRowClickSelection: true,
  rowSelection: 'multiple',
  rowModelType: 'infinite',
  // Max number of blocks that can be stored in the cache.
  maxBlocksInCache: 10,
  // Should be tested with a higher value as it will improve performance.
  maxConcurrentDatasourceRequests: 5,
  cacheOverflowSize: 50,
  // Size of each cache block by number of rows.
  // For many use cases (small datasets & comparisons) we can return all rows in one go. Nice!
  cacheBlockSize: 1000,
  // Disable "no sort". At least one column must always be sorted.
  sortingOrder: ['asc', 'desc'],
  rowGroupPanelShow: 'never',
  pivotMode: false,
  defaultColDef: {
    sortable: true,
    resizable: true,
    menuTabs: ['generalMenuTab'],
  },
  enableBrowserTooltips: true,
  maintainColumnOrder: true,
};

/**
 * Checkbox Column Definition
 * @type {ColDef}
 */
export const CHECKBOX_COLUMN_DEF: ColDef = {
  headerName: 'Select',
  colId: 'selected',
  field: 'selected',
  width: 34,
  minWidth: 34,
  maxWidth: 34,
  resizable: false,
  suppressMovable: true,
  suppressMenu: true,
  sortable: false,
  lockPosition: true,
  lockVisible: true,
  lockPinned: true,
  cellClass: 'checkbox',
  headerClass: 'checkbox',
  cellRenderer: CheckboxSelectionRendererComponent,
  headerComponent: HeaderSelectAllRendererComponent,
  pinned: 'left',
};

export const EXPAND_COLUMN_DEF: ColDef = {
  headerName: '',
  field: 'hasMatches',
  width: 28,
  minWidth: 28,
  maxWidth: 28,
  suppressMovable: true,
  suppressMenu: true,
  resizable: false,
  sortable: false,
  lockPosition: true,
  lockVisible: true,
  lockPinned: true,
  pinned: 'left',
  cellRenderer: 'agGroupCellRenderer',
};

export const CLIENT_CHECKBOX_COLUMN_DEF: ColDef = {
  headerName: '',
  checkboxSelection: true,
  width: 28,
  minWidth: 28,
  maxWidth: 28,
  suppressMovable: true,
  suppressMenu: true,
  resizable: true,
  sortable: false,
  lockPosition: true,
  lockVisible: true,
  lockPinned: true,
  cellClass: 'checkbox',
  cellRenderer: 'agGroupCellRenderer',
  pinned: 'left',
};

export const CHECKBOX_COLUMN_STATE: ColumnState = {
  colId: CHECKBOX_COLUMN_DEF.colId,
  hide: false,
  pinned: CHECKBOX_COLUMN_DEF.pinned,
  pivotIndex: null,
  rowGroupIndex: null,
  width: CHECKBOX_COLUMN_DEF.width,
};
