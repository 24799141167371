import { MemoizedSelector, createSelector } from '@ngrx/store';
import { ProfileService } from './profile.service';
import { ProfileTypes } from './profiles.model';
import { adapter } from './profiles.reducer';
import { AppState } from '../../core.store';
import { selectUserSettingsState } from '../user-settings.selectors';

export const { selectAll } = adapter.getSelectors();

export const selectProfiles = createSelector(selectUserSettingsState, (state) => state.profiles);

export const selectAllProfiles = createSelector(selectProfiles, selectAll);

export function selectProfilesForIdentifier<T extends keyof ProfileTypes>(
  identifier: string,
  feature: T,
): MemoizedSelector<AppState, ProfileTypes[T][]> {
  return createSelector(selectAllProfiles, (profiles) => {
    const normalizedIdentifier = ProfileService.normalizeProfileIdentifier(identifier);
    return profiles.filter(
      (profile): profile is ProfileTypes[T] =>
        profile.identifier === normalizedIdentifier && profile.feature === feature,
    );
  });
}
