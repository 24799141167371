import {
  ChangeDetectorRef,
  Directive,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { FormControlDirective, FormControlName, NgControl, NgForm, NgModel } from '@angular/forms';
import { CleanUp } from '../cleanup';
import { startWith, switchMapTo, take, takeUntil } from 'rxjs/operators';
import { defer, EMPTY, merge, Observable, Subject } from 'rxjs';

/**
 * ngFormControlValidator
 *
 * Applies the boostrap validator classes is-valid/is-invalid depending if the formcontrol is valid or not.
 * Bootstrap will automatically style form-controls with green/red boxes with one of these classes.
 *
 * <div class="invalid-feedback">...</div> can be used just below the host of this directive to automatically show
 * an form error helper message when this form control is invalid.
 *
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngFormControlValidator]',
  standalone: true,
})
export class NgFormControlValidatorDirective extends CleanUp implements OnInit, OnDestroy {
  @HostBinding('class.is-valid') isValidClass: boolean;
  @HostBinding('class.is-invalid') isInvalid: boolean;

  // Apply validation on blur (when un-focusing an input).
  @Input() validateOnBlur = false;
  @Input() canMarkPristineInvalid = true;
  /** Set to true to display a green tick when a form control is valid. */
  @Input() enableIsValidClass = true;

  private _isValid: boolean;
  private onBlur$: Subject<void>;
  private onFormSubmission$: Observable<Event>;
  private readonly control: FormControlName | FormControlDirective | NgModel;
  private hasBeenInvalid = false;

  constructor(
    private ngControl: NgControl,
    private cd: ChangeDetectorRef,
    // NOTE: NgForm is only available for non-reactive forms that use ngModel.
    // Thus this directive cannot be invoked by a form submission event.
    // TODO Find a way to listen to reactive forms submission event.
    @Optional() private form: NgForm,
  ) {
    super();

    // We need the `NgControl` Injection in the constructor, but we need to override the type to FormControlName or NgModel to be
    // able to access properties such as `formDirective`.
    if (
      this.ngControl instanceof FormControlName ||
      this.ngControl instanceof FormControlDirective ||
      this.ngControl instanceof NgModel
    ) {
      this.control = this.ngControl;
    } else {
      throw Error('ngFormControlValidator only supports FormControlName & NgModel');
    }

    this.onBlur$ = new Subject();
    this.onFormSubmission$ = this.form ? this.form.ngSubmit : EMPTY;
  }

  @HostListener('blur')
  onBlur() {
    this.onBlur$.next();
  }

  ngOnInit() {
    // Need to use 'defer' otherwise 'startWith(this.control.status)' will always return "VALID" instead of the actual status.
    const controlValid$: Observable<string> = defer(() =>
      this.control.statusChanges.pipe(
        startWith(this.control.status),
        takeUntil(this.ngUnsubscribe),
      ),
    );

    if (this.validateOnBlur) {
      merge(this.onBlur$, this.onFormSubmission$)
        .pipe(take(1), switchMapTo(controlValid$), takeUntil(this.ngUnsubscribe))
        .subscribe((valid) => this.setValidity(valid));
    } else {
      controlValid$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((valid) => this.setValidity(valid));
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.onBlur$.complete();
  }

  private set isValid(valid: boolean) {
    this._isValid = valid;
    if (this.enableIsValidClass) {
      this.isValidClass = valid;
    }
  }

  private get isValid(): boolean {
    return this._isValid;
  }

  private setValidity(controlStatus: string) {
    const dirty = this.control.dirty;
    const pristine = this.control.pristine;
    const isDisabled = controlStatus === 'DISABLED';
    const isPending = controlStatus === 'PENDING';
    const isInvalid = controlStatus === 'INVALID';
    const markAsInvalid = isInvalid && (this.canMarkPristineInvalid || dirty);
    // If it is valid but no changes yet; leave boring and not green; that's fine.
    // Only show green when the user has changed the value.
    const markAsValid = controlStatus === 'VALID' && dirty && this.hasBeenInvalid;
    const isPristine = controlStatus === 'VALID' && pristine;
    if (isInvalid) {
      this.hasBeenInvalid = true;
    }

    if (isDisabled) {
      this.isValid = false;
      this.isInvalid = false;
    } else if (isPending) {
      this.isValid = false;
      this.isInvalid = false;
    } else if (isPristine) {
      this.isValid = false;
      this.isInvalid = false;
    } else if (markAsInvalid) {
      this.isValid = false;
      this.isInvalid = true;
    } else if (markAsValid) {
      this.isValid = true;
      this.isInvalid = false;
    }
    this.cd.markForCheck();
  }
}
